import React, { useEffect, useMemo, useState } from 'react';
import { useSubscriptionContext } from '../../../src/pages/account';
import { SubscriptionComponent } from 'components/Dashboard/MySubscriptions/Subscription';
import { Accordion, Button } from 'react-bootstrap';
import chevbot from '../../assets/chev_bot.png';
import box from '../../assets/box.png';
import {
  calculateNextOrder,
  fetchIdFromUrl,
  getDateString,
  getImageSource,
  getProductImageUrl,
  getProductInfoFromId,
  roundPrice,
} from '../../../src/utils';
import product_placeholder from '../../assets/product_placeholder.png';
import Style from './style.module.scss';
type Props = {
  subscriptions?: [];
  toDate?: any;
};

const UpcommingOrders = ({ toDate = 3 }: Props) => {
  const {
    subscriptions,
    getPetBySubscriptionId,
    allProducts,
  } = useSubscriptionContext();

  const filterSubscription = useMemo(() => {
    const upcomingSubscriptions = (subscriptions || []).map(subscription => {
      const nextOrderDate = !subscription.skip_next
        ? subscription.next_order
        : calculateNextOrder(subscription.next_order, subscription.frequency);

      // Return a new object with the updated next order date
      return {
        ...subscription,
        next_order: nextOrderDate
      };
    });
    return upcomingSubscriptions;
  }, [subscriptions]);

  return (
    <div>
      <div className={Style.Title}>
        <h3>Upcoming Orders</h3>
      </div>
      <div>
        {filterSubscription.length ? (
          filterSubscription.map((subscription, index) => {
            let productId = subscription?.product;
            let pricePerDelivery = subscription.get_total;
            let productQuantity = subscription.box_count;
            let suggestedProducts = subscription.treats || [];
            let suggestedProductsPrice = 0;
            const rearrangeProducts = () => {
              const liveProducts =
                subscription.products?.filter(treat => {
                  const productId = fetchIdFromUrl(treat.product);
                  const product = getProductInfoFromId(allProducts, productId);
                  if (product && product.is_public) {
                    return true;
                  }
                }) || [];
              const restOfProducts =
                subscription.products?.filter(
                  treat => !liveProducts?.find(t => t.id === treat.id),
                ) || [];
              subscription.products = [...liveProducts, ...restOfProducts];
            };
            const pet = getPetBySubscriptionId(subscription.id);
            if (!pet?.id && subscription.products && subscription.products[0]) {
              rearrangeProducts();
              productId = subscription.products[0].product
                ? fetchIdFromUrl(subscription.products[0].product)
                : productId;
              pricePerDelivery = subscription.products[0].price
                ? subscription.products[0].price
                : pricePerDelivery;
              productQuantity = subscription.products[0].quantity
                ? subscription.products[0].quantity
                : productQuantity;
              suggestedProducts = subscription.products.filter(
                (product, index) => index > 0,
              );
            }
            const mainProduct =
              getProductInfoFromId(allProducts, productId) || ({} as Product);
              const mainProductImgSrc = getImageSource(mainProduct);
              const mainProductImg = mainProductImgSrc ? 
              getProductImageUrl(mainProductImgSrc) : !pet?.id ? 
              product_placeholder : box;
            suggestedProductsPrice = suggestedProducts.reduce(function(sum, product) {
              return sum + product.price;
            }, 0)
            return (
              <div
                key={'uncomming-subs' + index}
                className={`fmf-card ${Style.MainBox} ${Style.MarginFix} ${
                  index > 0 ? Style.MarginTop : ''
                }`}
              >
                <div className="fmf-card-hdr clearfix upcomming-orders">
                  <div className="float-left">
                    {pet && pet.name ? (
                      <span>
                        <span className="txt-capitalize">{pet.name}</span>'s
                        Subscription{' '}
                        <span className="color-theme">
                          {
                            <>
                              (Total : ${roundPrice(subscription.final_total)}
                              )
                            </>
                          }
                        </span>
                      </span>
                    ) : (
                      <span>
                        Sides Subscription
                        {subscription.display_id && subscription.display_id > 1
                          ? ' ' + subscription.display_id
                          : ''}{' '}
                        <span className="color-theme">
                          {
                            <>
                              (Total : ${roundPrice(subscription.final_total)}
                              )
                            </>
                          }
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="float-right">
                    <span>
                      {getDateString(
                        !subscription.skip_next
                          ? subscription.next_order
                          : calculateNextOrder(
                              subscription.next_order,
                              subscription.frequency,
                            ),
                      )}
                    </span>
                  </div>
                </div>
                <div className={`fmf-card-body ${Style.CardBody}`}>
                  {/* <div>title</div> */}
                  <div className="my-fur-item">
                    <div className="row">
                      <div className="col-sm">
                        <div className="img-wrapper">
                          <img width={100} src={mainProductImg} alt="" />
                        </div>
                      </div>
                      <div className="col-sm">
                        <div className="txt-wrapper">
                          {pet?.id && (
                            <div className="name">{pet?.id
                              ? (mainProduct?.upc?.includes("LAMB") ? "Dog Food Luxe Lamb" : "Core Kibble Diet"): mainProduct?.title}</div>
                          )}
                          <div className="dets">
                            <span>{`${productQuantity}x ${mainProduct?.title}`}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm">
                        <div className="txt-wrapper">
                          <div className="price-split-box">
                            <div className="split-value-row">
                              <h4 className="split-left-value">Sub total</h4>
                              <div className="split-right-value">${roundPrice(
                                  subscription?.pet
                                    ? subscription?.get_total_incl_treats
                                    : subscription.get_total,
                                )}</div>
                            </div>
                            <div className="split-value-row">
                              <h4 className="split-left-value">Shipping charge </h4>
                              <div className="split-right-value">${roundPrice(subscription.new_shipping_charge)}</div>
                            </div>
                            { subscription.discount ? <div className="split-value-row">
                              <h4 className="split-left-value">Discount</h4>
                              <div className="split-right-value">${subscription.discount}</div>
                            </div> : null}
                            <div className="split-value-row total-value-row">
                              <h4 className="split-left-value">Total</h4>
                              <div className="split-right-value">
                                ${roundPrice(subscription.final_total)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Accordion>
                  <div
                    className={`fmf-card subscription-details-accordian ${Style.MarginFix}`}
                  >
                    <div
                      className={`fmf-card-hdr clearfix cur-pointer ${Style.HeightFix}`}
                      onClick={() => {
                        document
                          .getElementById(
                            'freequency_control_accordian' + index,
                          )
                          .click();
                      }}
                    >
                      <div className="float-left">
                        <div className="subscription-details-container">
                          <span>
                            Addons <span className='color-theme'>{(suggestedProducts || [])?.length?<>${roundPrice(suggestedProductsPrice)}</>:<></>}</span> (
                            <span className="color-theme">
                              {(suggestedProducts || [])?.length}
                            </span>
                            )
                          </span>
                        </div>
                      </div>
                      <div className="float-right">
                        <Accordion.Toggle
                          id={'freequency_control_accordian' + index}
                          as={Button}
                          variant="link"
                          eventKey={'1'}
                        >
                          <img className="cur-pointer" src={chevbot} alt="" />
                        </Accordion.Toggle>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey={'1'}>
                      <div className="fmf-card-body">
                        <div className="my-fur-wrapper order-card-wrapper">
                          <div style={{ display: 'flex' }}>
                            <Addons
                              addons={suggestedProducts}
                              subscriptionId={subscription.id}
                              allProducts={allProducts}
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
            );
          })
        ) : (
          <p>No Upcoming orders.</p>
        )}
      </div>
    </div>
  );
};
const Addons = props => {
  const { addons = [], subscriptionId, allProducts } = props;

  return (
    <div className={Style.GridCcontainer}>
      {addons.map((addon: SubscriptionTreats, index: number) => {
        const productUrl = addon.product;
        const matches = productUrl.match(/(\d+)/);
        let addonInfo = {} as ProductInfo['nutritional_information'];
        if (matches !== null) {
          const productId = Number(matches[0]);
          const product =
            allProducts.find(
              (product: ProductInfo) => product.id === productId,
            ) || {};
          addonInfo = product?.nutritional_information;
        }

        return (
          <Addon
            addon={addon}
            key={index}
            subscriptionId={subscriptionId}
            allProducts={allProducts}
            addonInfo={addonInfo}
          />
        );
      })}
    </div>
  );
};
const Addon = (props: Props) => {
  const [viewNutritionInfo, setNutritionInfoVisibility] = useState(false);
  const [productInfo, setProductInfo] = useState({} as ProductInfo);
  const { addon, subscriptionId, allProducts, addonInfo } = props;
  const getProductInfo = async () => {
    if (addon.product) {
      const productId = fetchIdFromUrl(addon.product);
      const response = allProducts.find(it => it.id === productId);
      setProductInfo(response);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, [addon, allProducts]);

  const imgSrc = getImageSource(productInfo);
  const editButtonClasses = ['btn-item', 'text-right', 'mgn-0'];
  if (!productInfo?.is_public) {
    editButtonClasses.push('disabled');
  }
  return (
    <div className={Style.GridItem}>
      <div>
        <img
          height={120}
          width={120}
          src={imgSrc ? getProductImageUrl(imgSrc) : product_placeholder}
          alt=""
        />
      </div>
      <div className={Style.AlignRight}>
        <span>
          {addon.quantity}x {productInfo?.title}
        </span>
        <br />
        <span>
          {productInfo?.is_public ? `$${addon.price} per delivery` : 'N/A'}
        </span>
      </div>
    </div>
  );
};

export default React.memo(UpcommingOrders);
